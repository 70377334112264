import { Box, Grid, Typography } from "@mui/material";
import customTheme from "../customTheme";

const VerifyMyIdentity = () => (
  <Grid
    item
    xs={12}
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      mt: "80px",
      pb: "67px",
      backgroundColor: "#FEF9F7",
      textAlign: "center",
      pt: "64px",
      [customTheme.breakpoints.down("sm")]: {
        pl: "18px",
        pr: "18px",
        pt: "47px",
        pb: "17px",
      },
    }}
  >
    <Typography variant="h2">Comment vérifier mon identité ?</Typography>
    <Typography variant="body1" sx={{ mt: "16px" }}>
      Pour qualifier votre INS il vous suffit de faire vérifier votre identité
      en quelques étapes <b>rapides</b> à l’aide de votre <b>smartphone</b>.
    </Typography>
    <Grid
      container
      sx={{
        textAlign: "center",
        position: "relative",
        mt: "32px",
        width: "70%",
        [customTheme.breakpoints.down("sm")]: {
          gap: "30px",
        },
      }}
    >
      <Grid item xs={12} sm={4} sx={{ zIndex: 1 }}>
        <StepperStep
          text="Filmer votre pièce d’identité"
          src="images/step1.png"
        />
      </Grid>
      <Grid item xs={12} sm={4} sx={{ zIndex: 1 }}>
        <StepperStep
          text="Filmer votre visage et effectuer une action simple. Cette étape permet d’éviter les usurpations d’identité"
          src="images/step2.png"
        />
      </Grid>
      <Grid item xs={12} sm={4} sx={{ zIndex: 1 }}>
        <StepperStep text="Vérification de votre INS" src="images/step3.png" />
      </Grid>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="hr"
          sx={{
            position: "absolute",
            backgroundColor: "#DB3B00",
            width: "65%",
            height: "2px",
            top: "31px",
            border: "none",
            [customTheme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        />
      </Box>
      <Box
        component="div"
        sx={{
          borderLeft: "2px solid #DB3B00",
          height: "80%",
          position: "absolute",
          left: "24px",
          [customTheme.breakpoints.up("sm")]: {
            display: "none",
          },
        }}
      />
    </Grid>
  </Grid>
);

export default VerifyMyIdentity;

interface StepperStepProps {
  src: string;
  text: string;
}

const StepperStep = ({ src, text }: StepperStepProps) => (
  <Box
    sx={{
      textAlign: "center",
      alignItems: "center",
      justifyContent: "left",
      width: "100%",
      [customTheme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "row",
      },
    }}
  >
    <Box
      component="img"
      src={src}
      alt="Etape"
      sx={{
        zIndex: 1,
        [customTheme.breakpoints.down("sm")]: {
          width: "50px",
          height: "50px",
        },
      }}
    />
    <Typography
      variant="body1"
      sx={{
        color: " #192142",
        mt: "32px",
        width: "100%",
        [customTheme.breakpoints.down("sm")]: {
          textAlign: "left",
          mt: 0,
          ml: "24px",
        },
      }}
    >
      {text}
    </Typography>
  </Box>
);
