import "./App.css";
import { ThemeProvider } from "@mui/material";
import customTheme from "./customTheme";
import Page from "./components/Page";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <Page />
    </ThemeProvider>
  );
}

export default App;
