import { Box, Grid, Stack, Typography } from "@mui/material";
import customTheme from "../customTheme";

const WhoWeAre = () => (
  <Grid
    item
    xs={12}
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      mt: "80px",
    }}
  >
    <Box
      sx={{
        width: "80%",
        textAlign: "center",
      }}
    >
      <Typography variant="h2">Qui sommes-nous ?</Typography>
      <Typography variant="body1" sx={{ mt: "16px", mb: "32px" }}>
        ieSS est un groupement d'intérêt public dont la mission est
        d'accompagner l’ensemble des professionnels de santé et du médico-social
        de la région PACA dans leur transformation numérique.
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          m: " 0 100px",
          [customTheme.breakpoints.down("sm")]: {
            m: "0",
          },
        }}
      >
        <img src="images/monEspaceSante.png" alt="Logo MonEspaceSante" />
        <img src="images/ins.png" alt="Logo INS" />
        <img
          src="images/techAndTrust.png"
          alt="Logo Tech & Trust par DOCAPOSTE"
          height={24}
        />
      </Stack>
      <Typography variant="body1" sx={{ mt: "32px" }}>
        Pour en savoir plus, vous pouvez consulter{" "}
        <a
          href="https://esante.gouv.fr/"
          style={{ textDecoration: "underline", color: "#DB3B00" }}
        >
          le site de l’ANS
        </a>
      </Typography>
    </Box>
  </Grid>
);

export default WhoWeAre;
