import { Box, Button, Grid, Stack } from "@mui/material";
import customTheme from "../customTheme";

const Footer = () => (
  <Grid
    item
    xs={12}
    sx={{
      display: "flex",
      justifyContent: "center",
      width: "100%",
      [customTheme.breakpoints.down("sm")]: {
        borderTop: "1px solid #EDEDF2",
        mt: "64px",
      },
    }}
  >
    <Stack
      justifyContent="space-between"
      direction="row"
      sx={{
        width: "100%",
        m: "40px 32px",
        [customTheme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          [customTheme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        }}
      >
        <Button
          variant="text"
          sx={{
            fontSize: "12px",
            fontFamily: "Barlow",
            fontWeight: 400,
            [customTheme.breakpoints.up("sm")]: {
              display: "none",
            },
          }}
          onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
        >
          Haut de page
        </Button>

        <Button
          variant="text"
          sx={{ fontSize: "12px", fontFamily: "Barlow", fontWeight: 400 }}
        >
          Mentions légales
        </Button>
        <Button
          sx={{ fontSize: "12px", fontFamily: "Barlow", fontWeight: 400 }}
          variant="text"
        >
          CGU
        </Button>
        <Button
          sx={{ fontSize: "12px", fontFamily: "Barlow", fontWeight: 400 }}
          variant="text"
        >
          Plan du site
        </Button>
        <Button
          sx={{ fontSize: "12px", fontFamily: "Barlow", fontWeight: 400 }}
          variant="text"
        >
          Accessibilité
        </Button>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          gap: "48px",
          [customTheme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "16px",
          },
        }}
      >
        <Box
          component="img"
          src="images/grives.png"
          alt="Logo GRIVES"
          sx={{
            [customTheme.breakpoints.down("sm")]: {
              mb: "32px",
            },
          }}
        />
        <Box
          component="img"
          src="images/logo_ieSS.png"
          alt="Logo ieSS"
          sx={{
            [customTheme.breakpoints.down("sm")]: {
              mb: "32px",
            },
          }}
        />
        <Box
          component="img"
          src="images/techAndTrust.png"
          alt="Logo Tech & Trust par DOCAPOSTE"
          sx={{
            [customTheme.breakpoints.down("sm")]: {
              mb: "32px",
            },
          }}
        />
      </Stack>
    </Stack>
  </Grid>
);

export default Footer;
