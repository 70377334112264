import { createTheme } from "@mui/material/styles";
import { theme } from "@docaposte-agility/da-design-system";

const customedTheme = {
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#DB3B00",
          "&:hover": {
            backgroundColor: "#192142",
          },
        },
        text: {
          color: "#DB3B00",
          "&:hover": {
            color: "#192142",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#DB3B00",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          color: "#626876",
        },
        h2: {
          color: "#192142",
          [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
          },
        },
        h1: {
          [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
          },
        },
        h3: {
          color: "#192142",
          fontSize: "18px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
          },
        },
        body1: {
          color: "#626876",
          fontSize: "16px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
          },
        },
      },
    },
  },
};

const customTheme = createTheme(theme, customedTheme);
export default customTheme;
