import Header from "../components/Header";
import { Container, Grid, ThemeProvider } from "@mui/material";
import TitleSection from "../components/TitleSection";
import customTheme from "../customTheme";
import ContactSupport from "../components/ContactSupport";
import Footer from "../components/Footer";
import WhoWeAre from "../components/WhoWeAre";
import DataHealthSecurity from "../components/DataHealthSecurity";
import WhyAnINS from "../components/WhyAnINS";
import RecurringQuestions from "../components/RecurringQuestions";
import VerifyMyIdentity from "../components/VerifyMyIdentity";

function Page() {
  return (
    <ThemeProvider theme={customTheme}>
      <Container disableGutters>
        <Grid container justifyContent="center">
          <Header />
          <TitleSection />
          <VerifyMyIdentity />
          <WhyAnINS />
          <DataHealthSecurity />
          <WhoWeAre />
          <RecurringQuestions />
          <ContactSupport />
          <Footer />
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default Page;
