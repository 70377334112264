import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import customTheme from "../customTheme";
import { redirectToProcess } from "../utils/redirectionToProcess";

const TitleSection = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        mt: "64px",
        [customTheme.breakpoints.down("sm")]: {
          mt: "34px",
        },
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={10} sm={5}>
          <Typography variant="subtitle1" sx={{ color: "#626876" }}>
            Mon identité c’est ma sécurité
          </Typography>
          <Typography
            variant="h1"
            sx={{
              color: "#192142",
              mt: "8px",
              fontSize: "40px",
              lineHeight: "135%",
              width: "80%",
              [customTheme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            Qualifier votre Identité Nationale de santé
            <Tooltip
              title={
                <Box sx={{ p: "24px", backgroundColor: "#DB3B00" }}>
                  <Typography variant="body1" sx={{ color: "cobalt.white" }}>
                    L'identité nationale de santé (INS) vous suit tout au long
                    de votre vie. Elle permet d'identifier vos documents de
                    santé et elle contribue à la qualité et à la sécurité de
                    votre prise en charge dans votre parcours de santé. Pour
                    être utilisée par le professionnel qui vous prend en charge,
                    votre INS doit être qualifiée, c'est à dire que votre
                    identité doit être vérifiée.
                  </Typography>
                  <Box sx={{ textAlign: "center" }}>
                    <Button
                      variant="text"
                      sx={{ color: "cobalt.white" }}
                      onClick={redirectToProcess}
                    >
                      Vérifier mon identité
                    </Button>
                  </Box>
                </Box>
              }
              placement="bottom"
            >
              <CobaltIcon
                name="info"
                sx={{
                  color: "#DB3B00",
                  fontSize: "24px",
                  ml: "8px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: "#626876",
              mt: "24px",
              width: "80%",
              [customTheme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            Pour améliorer votre parcours de soins, votre médecin traitant a
            ouvert, avec votre accord, un dossier de coordination. Afin que les
            éléments de ce dossier puissent alimenter
            <Tooltip
              title={
                <Box sx={{ p: "24px", backgroundColor: "#DB3B00" }}>
                  <Typography variant="body1" sx={{ color: "cobalt.white" }}>
                    Mon Espace Santé{" "}
                    <img src="images/iconMES.png" alt="Icône MES" /> est le
                    service public pour gérer sa santé qui vous permet de
                    stocker et partager vos documents et données de santé en
                    toute confidentialité.
                  </Typography>
                </Box>
              }
              placement="bottom"
            >
              <Typography
                variant="body1"
                sx={{ color: "#DB3B00", display: "inline", cursor: "pointer" }}
              >
                {" "}
                votre espace santé{" "}
              </Typography>
            </Tooltip>
            (Mon Espace Santé), votre identité nationale de santé (INS) doit
            être qualifiée. Pour cela votre identité doit être vérifiée.
          </Typography>
          <Stack
            direction="row"
            sx={{
              mt: "32px",
              [customTheme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                [customTheme.breakpoints.down("sm")]: {
                  mb: "24px",
                },
              }}
              onClick={redirectToProcess}
            >
              Vérifier mon identité
            </Button>
            <Button
              href="https://www.youtube.com/watch?v=hosX9b-6ARs"
              target="_blank"
              variant="text"
              startIcon={<CobaltIcon name="play-circle" />}
            >
              Vidéo explicative
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            [customTheme.breakpoints.down("sm")]: {
              display: "none",
            },
            textAlign: "center",
          }}
        >
          <img src="images/selfie.svg" alt="Selfie" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TitleSection;
