import { Box, Button, Grid, Typography } from "@mui/material";
import customTheme from "../customTheme";

const ContactSupport = () => (
  <Grid
    item
    xs={12}
    sx={{ display: "flex", justifyContent: "center", mt: "80px" }}
  >
    <Box
      sx={{
        width: "80%",
        [customTheme.breakpoints.down("sm")]: {
          width: "100%",
        },
        backgroundColor: "#FEF9F7",
        p: "40px 0",
        textAlign: "center",
      }}
    >
      <Typography variant="h2">Contact et Support</Typography>
      <Typography variant="subtitle1" sx={{ mt: "16px" }}>
        Contactez-nous en cas de problème ou pour plus d’informations sur notre
        service.
      </Typography>
      <Button sx={{ p: "0 100px", mt: "24px" }}>Nous contacter</Button>
    </Box>
  </Grid>
);

export default ContactSupport;
