import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import customTheme from "../customTheme";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { ReactNode, SyntheticEvent, useState } from "react";

const RecurringQuestions = () => (
  <Grid
    item
    xs={12}
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      mt: "80px",
    }}
  >
    <Typography variant="h2" sx={{ mb: "32px" }}>
      Vos questions récurrentes
    </Typography>
    <Grid
      container
      sx={{
        width: "80%",
        [customTheme.breakpoints.down("sm")]: {
          width: "90%",
        },
      }}
      spacing={window.innerWidth < customTheme.breakpoints.values.sm ? 2 : 4}
    >
      <Grid item xs={12} sm={6} sx={{ "&.MuiGrid-root": { pt: 0 } }}>
        <Stack>
          <Question
            title="L’INS et mon parcours de soins"
            text="Suite à un problème de santé, je décide de consulter mon médecin généraliste. Mon médecin m’oriente vers un praticien spécialiste. Grâce à mon INS, mes informations médicales sont partagées aux différents professionnels et le compte rendu de mon médecin est disponible sur Mon Espace Santé."
          />
          <Question
            title="Quels sont les risques d’une mauvaise 
          identification ?"
            text="C’est le nouveau service public qui permet à chacun de stocker et partager ses documents et ses données de santé en toute sécurité pour être mieux soigné."
          />
        </Stack>
      </Grid>
      <Box
        component="hr"
        sx={{
          width: "100%",
          border: "none",
          borderTop: "1px solid #DADCE5",
          height: 0,
          marginLeft: "16px",
          [customTheme.breakpoints.up("sm")]: {
            display: "none",
          },
        }}
      />
      <Grid item xs={12} sm={6} sx={{ "&.MuiGrid-root": { pt: 0 } }}>
        <Stack>
          <Question
            title="Qu’est-ce que Mon Espace Santé ?"
            text="Une erreur d’identification peut entrainer une erreur d’administration médicamenteuse, un retard de prise en charge, une erreur de diagnostic ou thérapeutique."
          />
          <Question
            title="Qu’est-ce que l’identito-vigilance ?"
            text="L'identitovigilance est l'ensemble des mesures mises en œuvre pour fiabiliser votre identification lors des soins qui vous sont prodigués  et pour sécuriser vos données de santé."
          />
        </Stack>
      </Grid>
    </Grid>
  </Grid>
);

interface QuestionProps {
  title: string;
  text: string;
}

const Question = (props: QuestionProps) => {
  const { title, text } = props;

  const [expanded, setExpanded] = useState<ReactNode>();

  const onChangeAccordion = (e: SyntheticEvent, expanded: boolean) =>
    setExpanded(expanded);

  return (
    <Accordion
      onChange={onChangeAccordion}
      sx={{
        "&.MuiAccordion-root": {
          boxShadow: "none",
          borderRadius: 0,
          border: "none",
        },
        pb: "18px",
        pt: "18px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <CobaltIcon
            name={expanded ? "minus" : "plus"}
            sx={{
              borderRadius: "24px",
              border: "1px solid",
              p: "5px",
              borderColor: "#E0E0E0",
              color: "#FF5500",
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          variant="h3"
          sx={{
            [customTheme.breakpoints.down("sm")]: {
              width: "90%",
            },
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          "&.MuiAccordionDetails-root": {
            border: "none",
          },
        }}
      >
        <Typography>{text}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default RecurringQuestions;
