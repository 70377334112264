import { getApiKey } from "../services/service";

//on construit l'url en concatenant une partie fixe (stocké dans une variable d'environnnement) et une apiKey
const generateUrl = async () => {
  const key: string = await getApiKey();
  return `${window.__ENV__.REACT_APP_BASE_URL_ID360}${key}`;
};

export const redirectToProcess = async () => {
  const url = await generateUrl();
  if (url) window.location.href = url;
};
