import { Box, Grid, Typography } from "@mui/material";
import customTheme from "../customTheme";

const DataHealthSecurity = () => (
  <Grid
    item
    xs={12}
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      mt: "80px",
    }}
  >
    <Box
      sx={{
        width: "80%",
        [customTheme.breakpoints.down("sm")]: {
          width: "100%",
          p: "32px 0",
        },
        backgroundColor: "#FEF9F7",
        p: "70px 0",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          border: "1px solid #DB3B00",
          borderRadius: "12px",
          width: "80px",
          color: "#DB3B00",
          mb: "20px",
        }}
      >
        Sécurité
      </Typography>
      <Typography
        variant="h2"
        sx={{
          [customTheme.breakpoints.down("sm")]: {
            width: "90%",
          },
        }}
      >
        Traitement des données de santé et sécurité
      </Typography>
      <Box
        sx={{
          width: "60%",
          [customTheme.breakpoints.down("sm")]: {
            width: "90%",
          },
          mb: "18px",
        }}
      >
        <Typography variant="body1" sx={{ mt: "16px" }}>
          IeSS est un groupement d'intérêt public, partenaire numérique des
          professionnels de santé. Dans le cadre des processus
          d’identitovigilance, nous traitons vos données afin de s’assurer de la
          bonne identification de l'usager pour sécuriser ses données de santé,
          à toutes les étapes de sa prise en charge. Vos données sont traitées
          en France, par des agents habilités, dans le respect de la
          règlementation française et européenne
        </Typography>
      </Box>
    </Box>
  </Grid>
);

export default DataHealthSecurity;
