import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import customTheme from "../customTheme";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

import { redirectToProcess } from "../utils/redirectionToProcess";

const WhyAnINS = () => (
  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
    <Box
      sx={{
        width: "80%",
        [customTheme.breakpoints.down("sm")]: {
          width: "100%",
        },
        pt: "80px",
        textAlign: "center",
      }}
    >
      <Typography variant="h2">Pourquoi avoir une INS ?</Typography>
      <Grid
        container
        spacing={3}
        sx={{
          mt: "36px",
          [customTheme.breakpoints.down("sm")]: {
            mt: "12px",
          },
        }}
      >
        <Grid item xs={12} sm={4}>
          <Reason
            nameIcon="trending-up"
            title="Améliorer"
            text="Facilite les échanges entre professionnels de santé et améliore la coordination de mon parcours de soins."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Reason
            nameIcon="lock"
            title="Sécuriser"
            text="Assure la qualité et la sécurité de 
            ma prise en charge."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Reason
            nameIcon="share"
            title="Partager"
            text="Alimente Mon Espace Santé (espace numérique de santé) "
          />
        </Grid>
      </Grid>
      <Button
        sx={{
          p: "0 40px",
          mt: "24px",
          [customTheme.breakpoints.down("sm")]: {
            mt: "64px",
          },
        }}
        onClick={redirectToProcess}
      >
        Vérifier mon identité
      </Button>
    </Box>
  </Grid>
);

interface ReasonProps {
  nameIcon: any;
  title: string;
  text: string;
}

const Reason = (props: ReasonProps) => {
  const { nameIcon, title, text } = props;
  return (
    <Stack
      sx={{
        m: "0 10px",
        [customTheme.breakpoints.down("sm")]: {
          m: "0 25px",
        },
      }}
    >
      <IconButton disabled>
        <CobaltIcon
          name={nameIcon}
          sx={{
            color: "#DB3B00",
            backgroundColor: "#FEF9F7",
            p: 1,
            borderRadius: "24px",
          }}
        />
      </IconButton>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body1">{text}</Typography>
    </Stack>
  );
};

export default WhyAnINS;
